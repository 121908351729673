import { PortableText } from "@portabletext/react";
import { Button, Footer, Header, Text } from "@taskalliance/lms";
import { settingsQuery } from "@utils/groqQueries";
import { getClient } from "libs/sanity-library/src/lib/sanity.server";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import { groq } from "next-sanity";
import type { FunctionComponent } from "react";

import styles from "./index.module.scss";

const landingPageQuery = groq`
  *[_type in ["landingPage"]] {
    topTitle,
    pageImage {
      alt,
      "url": asset->url,
      "width": asset->metadata.dimensions.width,
      "height": asset->metadata.dimensions.height
    },
    pageMainTitle,
    pageSecondaryTitle,
    pageText,
    pageInfoTitle,
    pageInfoText,
    pageInfoTextPort,
    buttons
  }
`;

interface IndexProps {
  data: {
    landingPage: {
      topTitle?: string;
      pageImage: {
        alt: string;
        url: string;
        width?: number;
        height?: number;
      };
      buttons: {
        loginButton: string;
        registerButton: string;
      };
      pageInfoText: string;
      pageInfoTextPort: string;
      pageInfoTitle: string;
      pageMainTitle: string;
      pageSecondaryTitle: string;
      pageText: string;
    };
    setting: any;
  };
}

const Index: FunctionComponent<IndexProps> = ({ data }) => {
  const router = useRouter();
  const setting = data.setting;

  // Destructuring
  const {
    buttons,
    topTitle,
    pageInfoTextPort,
    pageInfoTitle,
    pageMainTitle,
    pageSecondaryTitle,
    pageText,
    pageImage
  } = data.landingPage[0];

  const navigate = (path) => {
    if (path) {
      // first parameter: pathname, second: as/alias
      router.push(`${path}`, `${path}`);
    }
  };

  //sanity portabletext styling/parsing
  const customComponents = {
    block: {
      h1: ({ children }: any) => (
        <Text as="h1" size="medium">
          {children}
        </Text>
      )
    }
  };

  return (
    <>
      <Head>
        <title>{setting[0]?.header?.headerTitle}</title>
        <meta>{pageSecondaryTitle}</meta>
      </Head>
      <main>
        <Header headerData={setting[0]?.header} />
        <article className={styles.index}>
          <div className={styles.introSection}>
            <div className={styles.introContent}>
              {topTitle && (
                <Text as="p" size="xlarge" customClassName={styles["topTitle"]}>
                  {topTitle}
                </Text>
              )}
              {pageImage && pageImage.url && (
                <div className={styles.introImage}>
                  <Image
                    src={pageImage.url}
                    alt={pageImage.alt}
                    width={pageImage.width}
                    height={pageImage.height}
                    priority={true}
                  />
                </div>
              )}
              <Text as="h1" customClassName={styles["mainTitle"]}>
                {pageMainTitle}
              </Text>
              <Text
                as="p"
                size="medium"
                customClassName={styles["secondaryTitle"]}
              >
                {pageSecondaryTitle}
              </Text>

              <Button
                variant="primary"
                size="large"
                attribute="login"
                onClick={() => navigate("/auth/login")}
              >
                {buttons.loginButton}
              </Button>
              <Button
                variant="secondary"
                size="medium"
                attribute="manager"
                onClick={() => navigate("/manager")}
              >
                {buttons.registerButton}
              </Button>
              <Text as="p" style={{ paddingBlockStart: "1rem" }}>
                {pageText}
              </Text>
            </div>
          </div>
          <div className={styles.infoSection}>
            <div className={styles.infoContent}>
              <Text as="h2">{pageInfoTitle}</Text>
              <PortableText
                components={customComponents}
                value={pageInfoTextPort}
              />
            </div>
          </div>
        </article>
        <Footer footerData={setting[0]?.footer} />
      </main>
    </>
  );
};

export async function getStaticProps({ preview = false }) {
  const landingPage = await getClient(preview).fetch(landingPageQuery);
  const setting = await getClient(preview).fetch(settingsQuery);

  return {
    props: {
      preview,
      data: { landingPage, setting }
    },
    revalidate: 1
  };
}

export default Index;
